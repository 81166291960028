import { Button, CircularProgress, IconButton, InputAdornment, TextField } from "@mui/material";
import { usePcareConfig, usePcareConfigUpdate } from "api/clinic";
import FormSection from "components/FormSection";
import useToaster from "context/ToasterContext";
import { UserData } from "entities/model/user";
import { useEffect, useState } from "react";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { useForm } from "react-hook-form";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import CardPcareStatus from "./CardPcareStatus";

type ConfigType = {
  cons_id: string;
  user_key: string;
  secret_key: string;
  user_name: string;
  password: string;
  provider_code: string;
}

const FormBpjs = () => {
  const auth = useAuthUser<UserData>();
  const toaster = useToaster();

  const clinic_id = auth?.clinicId;
  const getConfig = usePcareConfig();
  const updateConfig = usePcareConfigUpdate();

  const [isIntegrated, setIsIntegrated] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm<ConfigType>();

  const getPcareConfig = () => {
    getConfig.mutateAsync(clinic_id)
      .then((response) => {
        if (response?.status === 200) {
          const { data, message } = response.data;

          if (data) {
            const { pcare_config: config } = data;

            setValue("cons_id", config.cons_id);
            setValue("user_key", config.user_key);
            setValue("secret_key", config.secret_key);
            setValue("user_name", config.user_name);
            setValue("password", config.password);
            setValue("provider_code", config.provider_code);

            setIsIntegrated(config.pcare_status === "1");
          } else {
            throw new Error(message || "Data tidak ditemukan");
          }
        } else {
          throw new Error("Gagal mengambil data");
        }
      })
      .catch((err) => {
        toaster.open({
          title: "Gagal",
          message: err?.message,
          variant: "error",
          autoClose: true,
        });
      });
  }

  useEffect(() => {
    if (clinic_id && !isDirty && !getConfig.isLoading) {
      getPcareConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic_id]);

  const submitForm = async (val: ConfigType) => {
    if (clinic_id) {
      updateConfig.mutateAsync({
        clinic_id,
        ...val
      }).then((response) => {
        if (response?.status === 200) {
          const { success, message } = response.data;

          toaster.open({
            title: success ? "Berhasil" : "Gagal",
            message: message,
            variant: success ? "success" : "error",
            autoClose: true,
          });

          getPcareConfig();
        } else {
          throw new Error("Failed to save data");
        }
      }).catch((err) => {
        toaster.open({
          title: "Gagal",
          message: err,
          variant: "error",
          autoClose: true,
        });
      });
    }
  };

  return (
    <div className="max-w-lg">
      {getConfig.isLoading ? (
        <div className="flex justify-center items-center h-32">
          <CircularProgress />
        </div>
      ) : (
        <form noValidate onSubmit={handleSubmit(submitForm)}>
          <FormSection title="Konfirgurasi BPJS(PCare)" className="pt-2">
            <div className="space-y-6">
              <CardPcareStatus status={isIntegrated ? 'active' : 'inactive'} />

              <TextField
                required
                label="Cons ID"
                variant="outlined"
                fullWidth
                {...register("cons_id", { required: "Cons ID wajib diisi" })}
                error={!!errors.cons_id}
                helperText={errors.cons_id?.message}
                InputLabelProps={{
                  shrink: !!watch("cons_id"),
                }}
              />

              <TextField
                required
                label="User Key"
                variant="outlined"
                fullWidth
                {...register("user_key", { required: "User Key wajib diisi" })}
                error={!!errors.user_key}
                helperText={errors.user_key?.message}
                InputLabelProps={{
                  shrink: !!watch("user_key"),
                }}
              />

              <TextField
                required
                label="Secret Key"
                variant="outlined"
                fullWidth
                {...register("secret_key", { required: "Secret Key wajib diisi" })}
                error={!!errors.secret_key}
                helperText={errors.secret_key?.message}
                InputLabelProps={{
                  shrink: !!watch("secret_key"),
                }}
              />

              <TextField
                required
                label="User Name"
                variant="outlined"
                fullWidth
                {...register("user_name", { required: "User Name wajib diisi" })}
                error={!!errors.user_name}
                helperText={errors.user_name?.message}
                InputLabelProps={{
                  shrink: !!watch("user_name"),
                }}
              />

              <TextField
                required
                type={isPasswordVisible ? "text" : "password"}
                label="Password"
                variant="outlined"
                fullWidth
                {...register("password", { required: "Password wajib diisi" })}
                error={!!errors.password}
                helperText={errors.password?.message}
                InputLabelProps={{
                  shrink: !!watch("password"),
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {isPasswordVisible ? <HiOutlineEyeOff /> : <HiOutlineEye />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />

              <TextField
                required
                label="Kode Faskes"
                variant="outlined"
                fullWidth
                {...register("provider_code", { required: "Kode Faskes wajib diisi" })}
                error={!!errors.provider_code}
                helperText={errors.provider_code?.message}
                InputLabelProps={{
                  shrink: !!watch("provider_code"),
                }}
              />

              <div className="flex justify-end">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={updateConfig.isLoading}
                >
                  {updateConfig.isLoading ? "Menyimpan..." : "Simpan"}
                </Button>
              </div>
            </div>
          </FormSection>
        </form>
      )}
    </div>
  );
};

export default FormBpjs;
