import { Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Button, Card, CircularProgress, IconButton, InputAdornment, Link, TextField, Typography } from "@mui/material";
import useRegister from "api/auth/useRegister";
import LayoutPlain from "components/LayoutPlain";
import useToaster from "context/ToasterContext";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { RegisterParam } from "types/register";

interface RegisterForm extends RegisterParam {
  confirm_password: string;
}

const Register = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm<RegisterForm>();
  const { isRegistering, register: submitRegister } = useRegister();
  const { open: openToaster } = useToaster();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

  const onSubmit = async (val: RegisterForm) => {
    try {
      const { confirm_password, ...rest } = val;
      const response = await submitRegister(rest);
      if (response.success) {
        openToaster({
          title: "Pendaftaran Berhasil",
          message: "Silakan cek email Anda untuk melakukan verifikasi",
          variant: "success",
          autoClose: true,
        });

        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        throw new Error(response.message);
      }
    } catch (err) {
      const message = err instanceof Error ? err.message : "Silahkan coba lagi";

      openToaster({
        title: "Pendaftaran Belum Berhasil",
        message,
        variant: "error",
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, []);

  return (
    <LayoutPlain>
      <Helmet>
        <title>Daftar</title>
        <meta content="Daftar" property="og:title" />
        <meta content="Daftar" property="twitter:title" />
        <meta content="Daftar ke aplikasi Mitramedis. Gratis!" name="description" />
        <meta content="Daftar ke aplikasi Mitramedis. Gratis!" property="og:description" />
        <meta content="Daftar ke aplikasi Mitramedis. Gratis!" property="twitter:description" />
        <link rel="canonical" href="https://www.mitramedis.com/register" />
      </Helmet>
      <Transition appear show={show} as={Fragment}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-5"
          enterTo="opacity-100 translate-y-0"
        >

          <Card>
            <div className="max-w-4xl flex">
              {/* left section */}
              <div className="hidden w-full bg-gradient-to-b from-light-blue-800 to-blue-900 px-8 pb-12 pt-8 sm:block">
                <div className="align-center flex h-full flex-col justify-center gap-4">
                  <div className="flex grow flex-col items-center justify-center px-6 py-8">
                    <img
                      className="m-0 mx-auto"
                      src="/img_login.svg"
                      alt="Login"
                    />
                  </div>
                  <Typography variant="h3" color="white" className="!leading-[0.9em]">
                    Partner
                    <br />
                    sehat
                    <br />
                    bersama
                  </Typography>
                </div>
              </div>

              {/* right section */}
              <div className="flex w-full shrink-0 flex-col items-center justify-center space-y-4 p-4 py-8 sm:w-1/2 sm:p-8 lg:p-12">
                <div className="w-full space-y-4 md:space-y-6">
                  <Typography variant="h4">
                    Daftar
                  </Typography>
                  <form
                    autoComplete="off"
                    className="space-y-4 "
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <TextField
                      fullWidth
                      label="Nama Lengkap"
                      placeholder="Nama Lengkap"
                      type="text"
                      error={!!errors?.full_name}
                      helperText={errors?.full_name?.message}
                      {...register("full_name", {
                        required: {
                          value: true,
                          message: "Wajib diisi",
                        },
                      })}
                    />
                    <TextField
                      fullWidth
                      label="Nomor Telepon"
                      placeholder="Nomor Telepon"
                      type="number"
                      error={!!errors?.phone_number}
                      helperText={errors?.phone_number?.message}
                      {...register("phone_number", {
                        required: {
                          value: true,
                          message: "Wajib diisi",
                        },
                      })}
                    />
                    <TextField
                      fullWidth
                      label="Email"
                      placeholder="Email"
                      type="email"
                      error={!!errors?.email}
                      helperText={errors?.email?.message}
                      {...register("email", {
                        validate: (value) => {
                          if (!value) {
                            return "Email tidak boleh kosong";
                          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                            return "Email tidak valid";
                          }
                          return true;
                        }
                      })}
                    />
                    <TextField
                      fullWidth
                      label="Password"
                      placeholder="Password"
                      type={isPasswordVisible ? "text" : "password"}
                      error={!!errors?.password}
                      helperText={errors?.password?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                            >
                              {isPasswordVisible ? <HiOutlineEyeOff /> : <HiOutlineEye />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      {...register("password", {
                        required: {
                          value: true,
                          message: "Wajib diisi",
                        },
                      })}
                    />
                    <TextField
                      fullWidth
                      label="Ulangi Password"
                      placeholder="Ulangi Password"
                      type={isConfirmPasswordVisible ? "text" : "password"}
                      error={!!errors?.confirm_password}
                      helperText={errors?.confirm_password?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                            >
                              {isConfirmPasswordVisible ? <HiOutlineEyeOff /> : <HiOutlineEye />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      {...register("confirm_password", {
                        validate: (val: string) => {
                          if (val !== watch("password")) {
                            return "Password tidak cocok";
                          }
                          return true;
                        },
                      })}
                    />
                    <div className="pt-4">
                      <Button variant="contained" size="medium" type="submit" fullWidth>
                        {isRegistering ? <CircularProgress /> : "Daftar Sekarang"}
                      </Button>
                    </div>
                    <Typography fontSize={14} color="GrayText">
                      Sudah punya akun?{" "}
                      <Link href="/login">
                        Masuk
                      </Link>
                    </Typography>
                  </form>
                </div>
              </div>
            </div>
          </Card>
        </Transition.Child>
      </Transition>
    </LayoutPlain>
  );
};

export default Register;
