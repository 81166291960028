import { Typography } from "@mui/material";
import clsx from "clsx";
import React from "react";

interface ModalHeaderProps extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactNode;
};

export default function ModalHeader({ children, className }: ModalHeaderProps) {
  return (
    <div className={clsx('pt-6 pl-6 pr-8 pb-4', className)}>
      <Typography variant="h5">
        {children}
      </Typography>
    </div>

  );
}
