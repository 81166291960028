import { Button, CircularProgress } from '@mui/material';
import { useDetailUserManagement } from 'api/user-management';
import Modal from "components/Modal";
import ModalBody from "components/ModalBody";
import ModalFooter from "components/ModalFooter";
import ModalHeader from "components/ModalHeader";
import { USER_ROLE } from 'constants/global-variable';
import { UserManagementUpdateType } from 'types/user-management-update';
import UserForm from './UserForm';

interface UpdateUserProps {
    isOpen: boolean;
    isLoading: boolean;
    onClose: () => void;
    onUpdate: (user: UserManagementUpdateType) => void;
    user_id: string;
}

const ModalUpdateUser = ({ isOpen, isLoading, onClose, user_id, onUpdate }: UpdateUserProps) => {
    const { data: dataUser } = useDetailUserManagement({ user_id, enabled: isOpen && !!user_id });
    const userData = isOpen ? dataUser?.data?.data?.user : null;
    const isAdmin = Number(userData?.is_admin) === USER_ROLE.ADMIN;

    return (
        <Modal className='!max-w-2xl' open={isOpen} onClose={onClose}>
            <ModalHeader className='border-b'>Ubah User</ModalHeader>
            <ModalBody>
                {isOpen && !userData && (
                    <div className='flex justify-center items-center h-32'>
                        <CircularProgress />
                    </div>
                )}
                {isOpen && userData && (
                    <UserForm
                        isEdit
                        isAdmin={isAdmin}
                        formId='updateUserForm'
                        onSubmit={onUpdate}
                        defaultValues={{
                            email: userData?.email,
                            full_name: userData?.full_name,
                            phone_number: userData?.phone_number,
                            gender: userData?.gender,
                            birth_date: userData?.birth_date,
                            poly_clinic: userData?.poly_clinic,
                            type: userData?.type,
                            pcare_code: userData?.pcare_code,
                        }}
                    />
                )}
            </ModalBody>
            <ModalFooter>
                <Button
                    variant="contained"
                    form="updateUserForm"
                    type="submit"
                    disabled={isLoading}
                >
                    {isLoading ? 'Menyimpan...' : 'Ubah'}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalUpdateUser;
