import { Button, Chip, IconButton, Typography } from "@material-tailwind/react";
import { useUserDetail } from "api/user";
import { useAddUserManagement, useAllUserManagement, useChangePasswordUserManagement, useDeleteUserManagement, useUpdateUserManagement } from "api/user-management";
import ModalConfirmation from "components/organism/ModalConfirmation";
import { OPTIONS_POLYCLINIC_UNIT, OPTIONS_USER_TYPE } from "constants/options";
import useToaster from "context/ToasterContext";
import { UserData } from "entities/model/user";
import { Dropdown, Spinner, Table } from "flowbite-react";
import { useState } from "react";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { FaKey, FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import { HiDotsVertical, HiEye } from "react-icons/hi";
import { TbPlus } from "react-icons/tb";
import { useQueryClient } from "react-query";
import { UserManagementChangePasswordType } from "types/user-management-change-password";
import { UserManagementInsertType } from "types/user-management-create";
import { UserManagementUpdateType } from "types/user-management-update";
import AvatarUser from "./user-management/AvatarUser";
import ModalChangePassword from "./user-management/ModalChangePassword";
import ModalCreateUser from "./user-management/ModalCreateUser";
import ModalUpdateUser from "./user-management/ModalUpdateUser";
import ModalViewUser from "./user-management/ModalViewUser";

const ListUser = () => {
    const toaster = useToaster();
    const queryClient = useQueryClient();

    const auth = useAuthUser<UserData>();

    const user_id = auth?.userId;
    const { data: dataUser } = useUserDetail({ user_id });
    const userData = dataUser?.data?.data?.user;

    const [showAddUser, setShowAddUser] = useState(false);
    const [showUpdateUser, setShowUpdateUser] = useState(false);
    const [showDetailUser, setShowDetailUser] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [getUserId, setUserId] = useState('');

    const { data, isFetching, refetch } = useAllUserManagement();
    const addNewUser = useAddUserManagement();
    const updateUser = useUpdateUserManagement();
    const deleteMember = useDeleteUserManagement();
    const changePassword = useChangePasswordUserManagement();

    const handleAddNewUser = (val: UserManagementInsertType) => {
        addNewUser.mutateAsync({ ...val })
            .then((response) => {
                if (response.data?.success) {
                    toaster.open({
                        title: "Berhasil",
                        message: "Berhasil menambahkan user",
                        variant: "success",
                        autoClose: true,
                    });
                    queryClient.refetchQueries({ queryKey: ['get-user-detail', user_id] })
                    queryClient.refetchQueries({ queryKey: ['get-all-user-management'] })
                    queryClient.invalidateQueries({ queryKey: ['get-user-management-detail', user_id] })
                    setShowAddUser(false);
                } else {
                    toaster.open({
                        title: "Gagal",
                        message: response?.data.message,
                        variant: "error",
                        autoClose: true,
                    });
                }
            })
            .catch((err) => {
                toaster.open({
                    title: "Gagal",
                    message: err,
                    variant: "error",
                    autoClose: true,
                });
            });
    }

    const handleUpdateDataUser = (val: UserManagementUpdateType) => {
        updateUser.mutateAsync({ user_id: getUserId, ...val })
            .then((response) => {
                if (response.data?.success) {
                    toaster.open({
                        title: "Berhasil",
                        message: "Berhasil memperbarui user",
                        variant: "success",
                        autoClose: true,
                    });
                    queryClient.refetchQueries({ queryKey: ['get-user-detail', user_id] })
                    queryClient.refetchQueries({ queryKey: ['get-all-user-management'] })
                    queryClient.invalidateQueries({ queryKey: ['get-user-management-detail', user_id] })
                    setShowUpdateUser(false);
                } else {
                    toaster.open({
                        title: "Gagal",
                        message: response?.data.message,
                        variant: "error",
                        autoClose: true,
                    });
                }
            })
            .catch((err) => {
                toaster.open({
                    title: "Gagal",
                    message: err,
                    variant: "error",
                    autoClose: true,
                });
            });

        setSelectedUserId(null);
    }

    const detailUser = (user_id: string) => {
        setUserId(user_id);
        setShowDetailUser(true);
    }

    const updateUserById = (user_id: string) => {
        setUserId(user_id);
        setShowUpdateUser(true);
    }

    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

    const openDeleteConfirmation = (id: string) => {
        setSelectedUserId(id);
        setShowConfirmDelete(true);
    };

    const handleDeleteMember = (id: string) => {
        deleteMember.mutateAsync({ user_id: id })
            .then((response) => {
                if (response.data?.success) {
                    toaster.open({
                        title: "Berhasil",
                        message: "Berhasil menghapus user",
                        variant: "success",
                        autoClose: true,
                    });
                    refetch();
                } else {
                    toaster.open({
                        title: "Gagal",
                        message: response?.data.message,
                        variant: "error",
                        autoClose: true,
                    });
                }
            })
            .catch((err) => {
                toaster.open({
                    title: "Gagal",
                    message: err,
                    variant: "error",
                    autoClose: true,
                });
            });;

        setSelectedUserId(null);
        setShowConfirmDelete(false);
    }

    const changePasswordById = (user_id: string) => {
        setUserId(user_id);
        setShowChangePassword(true);
    }

    const handleChangePasswordUser = (userId: string, val: UserManagementChangePasswordType) => {
        changePassword.mutateAsync({ user_id: userId, ...val })
            .then((response) => {
                if (response.data?.success) {
                    toaster.open({
                        title: "Berhasil",
                        message: "Berhasil mengganti password",
                        variant: "success",
                        autoClose: true,
                    });
                    refetch();
                } else {
                    toaster.open({
                        title: "Gagal",
                        message: response?.data.message,
                        variant: "error",
                        autoClose: true,
                    });
                }
            })
            .catch((err) => {
                toaster.open({
                    title: "Gagal",
                    message: err,
                    variant: "error",
                    autoClose: true,
                });
            });

        setSelectedUserId(null);
    }

    return (
        <>
            <div className="mb-8 flex items-center justify-between gap-8">
                <div>
                    <Typography variant="h5" color="blue-gray">
                        Daftar User
                    </Typography>
                    <Typography color="gray" className="mt-1 font-normal">
                        Daftar pengguna Klinik
                    </Typography>
                </div>
                <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                    <Button
                        className="flex items-center gap-3"
                        size="sm"
                        onClick={() => setShowAddUser(true)}
                    >
                        <TbPlus
                            strokeWidth={2} className="h-4 w-4" /> Tambah User
                    </Button>
                </div>
            </div>
            <div className="overflow-x-auto">
                <Table>
                    <Table.Head>
                        <Table.HeadCell>User</Table.HeadCell>
                        <Table.HeadCell>Role</Table.HeadCell>
                        <Table.HeadCell>Poli/Unit</Table.HeadCell>
                        <Table.HeadCell>Nomor Telepon</Table.HeadCell>
                        <Table.HeadCell>Tanggal Lahir</Table.HeadCell>
                        <Table.HeadCell>Status</Table.HeadCell>
                        <Table.HeadCell>
                            <span className="sr-only">Aksi</span>
                        </Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        <Table.Row key={userData?.user_id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                <div className="flex items-center gap-3">
                                    <AvatarUser name={userData?.full_name} />
                                    <div className="flex flex-col">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {userData?.full_name}
                                        </Typography>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal opacity-70"
                                        >
                                            {userData?.email}
                                        </Typography>
                                    </div>
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                <div className="flex flex-col">
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                    >
                                        Pemilik
                                    </Typography>
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal opacity-70"
                                    >
                                        {OPTIONS_USER_TYPE.find((user) => user.key === Number(userData?.type))?.label}
                                    </Typography>
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                {OPTIONS_POLYCLINIC_UNIT.find((poly) => poly.key === Number(userData?.poly_clinic))?.label}
                            </Table.Cell>
                            <Table.Cell>
                                {userData?.phone_number}
                            </Table.Cell>
                            <Table.Cell>
                                {userData?.birth_date}
                            </Table.Cell>
                            <Table.Cell>
                                <Chip
                                    variant="ghost"
                                    size="sm"
                                    value={userData?.status === '1' ? 'Aktif' : 'Tidak Aktif'}
                                    color={userData?.status === '1' ? "green" : "blue-gray"}
                                    className="text-center"
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <div className="flex gap-1">
                                    <Dropdown
                                        label={<IconButton variant="text" size="sm"><HiDotsVertical /> </IconButton>}
                                        arrowIcon={false}
                                        inline={true}
                                        placement="left-start"
                                    >
                                        <Dropdown.Item
                                            className="bg-white text-black"
                                            onClick={() => detailUser(userData?.user_id)}>
                                            <div className="flex items-center">
                                                <HiEye />
                                                <p className="ml-2">Detail User</p>
                                            </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className="bg-white text-black"
                                            onClick={() => updateUserById(userData?.user_id)}>
                                            <div className="flex items-center">
                                                <FaPencilAlt />
                                                <p className="ml-2">Ubah</p>
                                            </div>
                                        </Dropdown.Item>
                                    </Dropdown>
                                </div>
                            </Table.Cell>
                        </Table.Row>
                        {isFetching && (
                            <Table.Row>
                                <Table.Cell colSpan={7}>
                                    <Spinner size="lg" className="mx-auto w-full" />
                                </Table.Cell>
                            </Table.Row>
                        )}
                        {!isFetching && data?.data?.data?.user?.map((item) => (
                            <Table.Row key={item.user_id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                    <div className="flex items-center gap-3">
                                        <AvatarUser name={item.full_name} />
                                        <div className="flex flex-col">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                {item.full_name}
                                            </Typography>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal opacity-70"
                                            >
                                                {item.email}
                                            </Typography>
                                        </div>
                                    </div>
                                </Table.Cell>
                                <Table.Cell>
                                    <div className="flex flex-col">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            Anggota
                                        </Typography>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal opacity-70"
                                        >
                                            {item.type_desc ?? '-'}
                                        </Typography>
                                    </div>
                                </Table.Cell>
                                <Table.Cell>
                                    {item.poly_clinic_desc}
                                </Table.Cell>
                                <Table.Cell>
                                    {item.phone_number}
                                </Table.Cell>
                                <Table.Cell>
                                    {item.birth_date}
                                </Table.Cell>
                                <Table.Cell>
                                    <Chip
                                        variant="ghost"
                                        size="sm"
                                        value={item.status_desc}
                                        color={item.status === '1' ? "green" : "blue-gray"}
                                        className="text-center"
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <div className="flex gap-1">
                                        <Dropdown
                                            label={<IconButton variant="text" size="sm"><HiDotsVertical /> </IconButton>}
                                            arrowIcon={false}
                                            inline={true}
                                            placement="left-start"
                                        >
                                            <Dropdown.Item
                                                className="bg-white text-black"
                                                onClick={() => detailUser(item.user_id)}>
                                                <div className="flex items-center">
                                                    <HiEye />
                                                    <p className="ml-2">Detail User</p>
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                className="bg-white text-black"
                                                onClick={() => updateUserById(item.user_id)}>
                                                <div className="flex items-center">
                                                    <FaPencilAlt />
                                                    <p className="ml-2">Ubah</p>
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                className="bg-white text-black"
                                                onClick={() => openDeleteConfirmation(item.user_id)}>
                                                <div className="flex items-center">
                                                    <FaTrashAlt />
                                                    <p className="ml-2">Hapus</p>
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                className="bg-white text-black"
                                                onClick={() => changePasswordById(item.user_id)}>
                                                <div className="flex items-center">
                                                    <FaKey />
                                                    <p className="ml-2">Ganti Password</p>
                                                </div>
                                            </Dropdown.Item>
                                        </Dropdown>
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>

            <ModalCreateUser
                isLoading={addNewUser.isLoading}
                isOpen={showAddUser}
                onClose={() => setShowAddUser(false)}
                onSave={handleAddNewUser}
            />

            <ModalViewUser
                isOpen={showDetailUser}
                onClose={() => setShowDetailUser(false)}
                user_id={getUserId}
            />

            <ModalUpdateUser
                isLoading={updateUser.isLoading}
                isOpen={showUpdateUser}
                onClose={() => setShowUpdateUser(false)}
                user_id={getUserId}
                onUpdate={handleUpdateDataUser}
            />

            <ModalChangePassword
                isOpen={showChangePassword}
                onClose={() => setShowChangePassword(false)}
                user_id={getUserId}
                onChangePassword={(val) => handleChangePasswordUser(getUserId, val)}
            />

            <ModalConfirmation
                open={showConfirmDelete}
                onClose={() => setShowConfirmDelete(false)}
                type="warning"
                title="Hapus data user ini?"
                message="Data user ini akan dihapus secara permanen"
                primaryAction="Ya, Hapus"
                onPrimaryActionClick={() => handleDeleteMember(selectedUserId)}
                secondaryAction="Batal"
                onSecondaryActionClick={() => setShowConfirmDelete(false)}
            />
        </>
    );
};

export default ListUser;
