import { MenuItem, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useDoctor from "api/bpjs/useDoctor";
import usePolyclinic from "api/constant/usePolyclinic";
import FormSection from "components/FormSection";
import { PCARE_STATUS, USER_ROLE } from "constants/global-variable";
import { OPTIONS_GENDER_USER, OPTIONS_USER_TYPE } from "constants/options";
import useAppContext from "context/AppContext";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { UserManagementUpdateType } from "types/user-management-update";

interface UserFormFields extends UserManagementUpdateType {
  password?: string;
}

interface UserFormProps {
  formId?: string;
  isEdit: boolean;
  isAdmin: boolean;
  defaultValues?: UserFormFields;
  onSubmit: (data: UserFormFields) => void;
}

function UserForm({
  formId = 'createUserForm',
  isEdit,
  isAdmin,
  defaultValues,
  onSubmit,
}: UserFormProps) {
  const {
    formState: { errors },
    handleSubmit,
    control,
    register,
    setValue,
    watch,
  } = useForm<UserFormFields>({
    defaultValues
  });

  const { clinicDetail } = useAppContext();
  const pcareStatus = clinicDetail?.pcare_status || '0';

  const { data: doctorData } = useDoctor();
  const doctorOptions = doctorData?.data?.data?.response?.list || [];

  const { data: polyclinicData } = usePolyclinic({ is_sick_clinic: '1' });
  const polyclinicOptions = polyclinicData?.data?.poly_clinic || [];

  return (
    <form id={formId} noValidate onSubmit={handleSubmit(onSubmit)}>
      <FormSection title="Data Diri" className='!pt-4 !pb-2'>
        <div className="grid md:grid-cols-12 gap-y-6 gap-x-4 my-4">
          <TextField
            className={isEdit ? 'md:col-span-12' : 'md:col-span-6'}
            label="Email"
            required
            fullWidth
            type="email"
            error={Boolean(errors?.email)}
            helperText={errors?.email?.message}
            disabled={isEdit && isAdmin}
            inputProps={{
              form: { autocomplete: 'off' }
            }}
            {...register('email', {
              required: {
                value: true,
                message: 'Wajib diisi',
              },
              validate: (value) => {
                if (!value) {
                  return "Email tidak boleh kosong";
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                  return "Email tidak valid";
                }
                return true;
              }
            })}
          />

          {!isEdit && (
            <TextField
              className='md:col-span-6'
              label="Password"
              required={!isEdit}
              fullWidth
              type="password"
              error={Boolean(errors?.password)}
              helperText={errors?.password?.message}
              disabled={isEdit && isAdmin}
              {...register('password', {
                required: {
                  value: !isEdit,
                  message: 'Wajib diisi',
                },
              })}
            />
          )}

          <TextField
            className='md:col-span-12'
            label="Nama Lengkap"
            required
            fullWidth
            type="text"
            error={Boolean(errors?.full_name)}
            helperText={errors?.full_name?.message}
            disabled={isEdit && isAdmin}
            {...register('full_name', {
              required: {
                value: true,
                message: 'Wajib diisi',
              },
            })}
          />

          <TextField
            className='md:col-span-4'
            label="Nomor Telepon"
            required
            fullWidth
            type="number"
            error={Boolean(errors?.phone_number)}
            helperText={errors?.phone_number?.message}
            disabled={isEdit && isAdmin}
            {...register('phone_number', {
              required: {
                value: true,
                message: 'Wajib diisi',
              },
            })}
          />

          <Controller
            control={control}
            name="birth_date"
            rules={{
              required: {
                value: true,
                message: 'Wajib diisi',
              },
            }}
            render={({ field }) => (
              <DatePicker
                className='md:col-span-4'
                label="Tanggal Lahir"
                maxDate={moment()}
                {...field}
                value={field.value ? moment(field.value) : undefined}
                onChange={(date) => field.onChange(date.format("YYYY-MM-DD"))}
                slotProps={{
                  textField: {
                    required: true,
                    fullWidth: true,
                    error: Boolean(errors?.birth_date),
                    helperText: errors?.birth_date?.message,
                    disabled: isEdit && isAdmin
                  }
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="gender"
            rules={{
              required: {
                value: true,
                message: 'Wajib diisi',
              },
            }}
            render={({ field }) => (
              <TextField
                select
                fullWidth
                required
                className='md:col-span-4'
                label="Jenis Kelamin"
                error={Boolean(errors?.gender)}
                helperText={errors?.gender?.message}
                disabled={isEdit && isAdmin}
                defaultValue=""
                {...field}
                value={field.value ?? ""}
              >
                {OPTIONS_GENDER_USER.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </div>
      </FormSection>

      <FormSection title='Hak Akses' className='!pt-6 !pb-2'>
        <div className="grid md:grid-cols-12 gap-y-6 gap-x-4 my-4">
          <Controller
            control={control}
            name="type"
            rules={{
              required: {
                value: true,
                message: 'Wajib diisi',
              },
              onChange: (event) => {
                const value = event.target.value;
                if (value !== USER_ROLE.DOCTOR) {
                  setValue('poly_clinic', "");
                  setValue('type', value)
                }
              }
            }}
            render={({ field }) => (
              <TextField
                select
                fullWidth
                required
                label="Role"
                className='md:col-span-6'
                error={Boolean(errors?.type)}
                helperText={errors?.type?.message}
                defaultValue=""
                {...field}
                value={field.value ?? ""}
              >
                {OPTIONS_USER_TYPE.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          {Number(watch('type')) === USER_ROLE.DOCTOR && (
            <Controller
              control={control}
              name="poly_clinic"
              rules={{
                required: {
                  value: true,
                  message: 'Wajib diisi',
                },
              }}
              render={({ field }) => (
                <TextField
                  select
                  fullWidth
                  required={Number(watch('type')) === USER_ROLE.DOCTOR}
                  className='md:col-span-6'
                  label="Poli Klinik"
                  error={Boolean(errors?.poly_clinic)}
                  helperText={errors?.poly_clinic?.message}
                  defaultValue=""
                  {...field}
                  value={field.value ?? ""}
                >
                  {polyclinicOptions?.map((i) => (
                    <MenuItem key={i.poly_clinic_id} value={i.poly_clinic_id}>
                      {i.poly_clinic_name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          )}
        </div>
      </FormSection>

      {Number(watch('type')) === USER_ROLE.DOCTOR && pcareStatus === PCARE_STATUS.ACTIVE && (
        <FormSection title='PCare' className='!pt-6 !pb-2'>
          <div className="grid md:grid-cols-12 gap-y-6 gap-x-4 my-4">
            <Controller
              name="pcare_code"
              control={control}
              render={({ field }) => (
                <TextField
                  select
                  fullWidth
                  className='md:col-span-12'
                  label="Kode Dokter"
                  error={Boolean(errors?.pcare_code)}
                  helperText={errors?.pcare_code?.message || 'Biarkan kosong jika klinik Anda tidak menggunakan layanan PCare'}
                  defaultValue=""
                  {...field}
                  value={field.value ?? ""}
                >
                  {doctorOptions?.map((item) => (
                    <MenuItem key={item.kdDokter} value={String(item.kdDokter)}>
                      {`${item.kdDokter} - ${item.nmDokter}`}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </div>
        </FormSection>
      )}
    </form>
  )
}

export default UserForm;