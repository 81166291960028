import { Button } from "@mui/material";
import Modal from "components/Modal";
import ModalBody from "components/ModalBody";
import ModalFooter from "components/ModalFooter";
import ModalHeader from "components/ModalHeader";
import { UserManagementInsertType } from 'types/user-management-create';
import UserForm from './UserForm';

interface CreateUserProps {
    isOpen: boolean;
    isLoading: boolean;
    onClose: () => void;
    onSave: (user: UserManagementInsertType) => void;
}

const ModalCreateUser = ({ isOpen, isLoading, onClose, onSave }: CreateUserProps) => {
    return (
        <Modal className='!max-w-2xl' open={isOpen} onClose={onClose}>
            <ModalHeader className='border-b'>Tambah User</ModalHeader>
            <ModalBody>
                {isOpen && (
                    <UserForm
                        formId='createUserForm'
                        isEdit={false}
                        isAdmin={false}
                        onSubmit={onSave}
                    />
                )}
            </ModalBody>
            <ModalFooter>
                <Button
                    variant="contained"
                    form="createUserForm"
                    type="submit"
                    disabled={isLoading}
                >
                    {isLoading ? 'Menyimpan...' : 'Simpan'}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalCreateUser;
