import axios, { AxiosResponse } from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useQuery } from "react-query";
import { BpjsDoctorResponse } from "types/bpjs-doctor";

interface Response extends AxiosResponse {
    data: BpjsDoctorResponse
}

const useDoctor = () => {
    const signOut = useSignOut();
    const headers = useAuthHeaders();

    return useQuery<unknown, unknown, Response>({
        queryKey: ['get-all-pcare-doctor'],
        queryFn: async () => {
            try {
                return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/pcare/doctor`, { headers })
            } catch (err) {
                console.error(err);
                if (err?.response?.status === 401) signOut();
            }
        },
    })
}

export default useDoctor;
