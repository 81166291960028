import { Typography } from "@material-tailwind/react";
import clsx from "clsx";

interface SectionProps extends React.HTMLProps<HTMLDivElement> {
  title?: string;
  actionElement?: React.ReactNode,
}

const FormSection = ({ title, actionElement, className, children }: SectionProps) => {
  return (
    <section
      className={clsx(
        "border-b pb-8 pt-10 last:border-b-0",
        className
      )}
    >
      <div className="flex justify-between items-center mb-6 empty:mb-0">
        {title && (
          <div className="border-l-4 border-sky-500 pl-2">
            <Typography variant="h6" className="tracking-normal">
              {title}
            </Typography>
          </div>
        )}
        {actionElement}
      </div>
      {children}
    </section>
  );
};

export default FormSection;
