import { Typography } from "@mui/material";
import { ReactNode } from "react";
import { PiCactusDuotone } from "react-icons/pi";

interface EmptyDataProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: string | ReactNode;
}

const EmptyData = ({ children, className }: EmptyDataProps) => {
  const childrenIsString =
    typeof children === "string" || children === undefined;

  return (
    <div className={`flex h-full min-h-[48px] w-full items-center justify-center gap-2 bg-gray-50 ${className}`}>
      <PiCactusDuotone className="text-2xl text-gray-500" />
      {childrenIsString ? (
        <Typography variant="body1" className="text-gray-500">
          {children || "Belum ada data"}
        </Typography>
      ) : (
        children
      )}
    </div>
  );
};

export default EmptyData;
