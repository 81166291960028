import axios from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useQuery } from "react-query";
import { PolyclinicBPJSResponse } from "types/bpjs-polyclinic";

type Props = {
    is_sick_clinic?: string
}

const usePolyclinic = (payload?: Props) => {
    const signOut = useSignOut();
    const headers = useAuthHeaders();

    const query = new URLSearchParams(payload);

    return useQuery<unknown, unknown, PolyclinicBPJSResponse>({
        queryKey: ['get-all-pcare-poly-clinic', payload?.is_sick_clinic],
        queryFn: async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/constant/poly-clinic?${query}`, { headers });
                return response.data;
            } catch (err) {
                console.error(err);
                if (err?.response?.status === 401) signOut();
                throw err;
            }
        },
    });
}

export default usePolyclinic;
